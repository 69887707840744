import { PageProps } from 'gatsby';
import { PageData } from 'packages/innedit';
import { parse } from 'query-string';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import page from '~/params/page.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PagePagesCreate: FC<PageProps & EspaceProps & UserProps> = props => {
  const {
    espace,
    user,
    location: { search },
    params: { channelId, espaceId },
  } = props;

  let params;
  if (search) {
    params = parse(search);
  }

  const model = new PageData({ channelId, espaceId, params: page });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          docId={model.getNewDocId()}
          initializeData={params}
          itemPathnamePrefix={`/espaces/${espaceId}/channels/${channelId}/pages/`}
          model={model}
          name="page"
          params={{ channelId }}
          type="create"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PagePagesCreate);
